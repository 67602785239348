.auth {
    @apply ml-0;
    @apply flex flex-col items-center;
    @apply lg:mt-0;
}
.auth a,
.auth button {
    @apply whitespace-nowrap;
}
/*
.auth div.button {
    background: #ccc;
}
*/

#signup {
    @apply border-2 border-dotted border-black dark:border-white text-black dark:text-white hover:border-primary hover:bg-primary hover:text-pureWhite;
}

/*
#signin {
    @apply border-none;
}
#signin:hover {
    @apply bg-transparent text-primary;
}
*/

.signedin:hover {
    @apply text-black !important;
}
.signedin nav a:hover {
    @apply text-primary !important;
}

.signedin button {
    overflow: hidden;
    min-width: 52px;
    min-height: 52px;
    max-width: 52px;
    max-height: 52px;
    padding: 0!important;
    border-radius: 50%;
    @apply whitespace-nowrap;
}
.signedin button {
    padding: 0;
}
.signedin button.photo {
    @apply border-none !important;
    display: none;
}
.signedin button:focus {
    outline: none;
}

.dropdown {
    /*display: none;*/
    width: 160px;
}
.dropdown ul {
    display: flex;
    flex-flow: column;
    text-align: left;
}
.dropdown li {
    margin-top: 2px;
}
.dropdown a {
    display: block;
    background: var(--clrLightShades);
    @apply text-black text-center;
}
.dropdown a:hover {
    @apply text-primary;
}

.dropdown.active {
    display: block;
}

@screen lg {
    .auth {
        @apply flex-row;
    }
    .signedin {
        max-height: 52px;
    }
    .signedin button.photo {
        display: block;
    }
    .dropdown {
        display: none;
        margin-left: calc( -1 * (160px - 52px) );
        position: absolute;
    }
    .dropdown a {
        @apply text-left;
    }
}